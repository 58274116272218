.container {
  width: 100%;
  height: 90%;
  z-index: 0;
}

.uaFooter {
  height: 25%;
}

.createStoryButton {
  font-size: 2em;
  background-color: transparent;
  text-align: center;
  color: white;
  text-decoration: none;
  font-weight: 700;
  float: right;
  margin-right: 10%;
  margin-top: 2%;
}

.headlineDesign {
  width: 100%;
  position: absolute;
  top: 20vh;
  left: 5%;
}

.headline {
  font-size: 15vh;
  color: white;
}

.subHeadline {
  font-size: 7vh;
  width: 50vw;
}

.storyLabButton {
  position: absolute;
  top: 50vh;
  right: 2vw;
  background-color: transparent;
  font-size: 2rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.mapButton {
  position: absolute;
  top: 50vh;
  left: 2vw;
  background-color: transparent;
  font-size: 2rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.mapButtonHome {
  position: fixed; /* Keeps the button fixed at a specific position on the screen */
  bottom: 10px; /* Distance from the bottom of the screen */
  left: 50%; /* Centers the button horizontally */
  transform: translateX(-50%);
  background-color: transparent;
  font-size: 2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.faAnglesDown,
.faAnglesRight,
.faAnglesLeft {
  animation: blink 2s linear infinite;
}

.mapButtonHome,
.storyLabButton,
.mapButton {
  font-family: "Mate";
}

.mapButtonHome:hover,
.storyLabButton:hover,
.mapButton:hover {
  color: grey;
  animation: none;
}

@keyframes blink {
  0% {
    opacity: 0.25;
  }

  15% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.75;
  }

  45% {
    opacity: 1;
  }

  60% {
    opacity: 0.75;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.25;
  }
}

.homepageContainerMiddle {
  position: absolute;
  top: 100%;
  height: 50%;
}

.mapBox {
  width: 100%;
}

.footer {
  position: relative;
  top: 100%;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 600px) {
  .headline {
    font-size: 20vh;
  }

  .storyLabButton,
  .mapButton,
  .mapButtonHome {
    font-size: 1.2rem;
    color: black;
   
  }

  .storyLabButton,
  .mapButton {
    top: 30vh
  }
}
