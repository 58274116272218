.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4423e7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d20ad6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.approve {
  background-color: white;
  margin: 0 auto;
  padding-bottom: 5%;
  padding-top: 5%;
  max-width: 0%;
  min-width: 0%;
  border-radius: 25px;
}

.approvalForm {
  background-color: white;
  color: black;
  padding-left: 50px;
  padding-bottom: 5%;
  display: inline;
}

.deleteButton{

  color: white;
  border-radius: 50%;
  border-style: none;
  padding: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  justify-content: center;
  background-color: rgb(255, 0, 0, 0.75);
  justify-content: center;
  text-align: center;

}

.submit-approve {
  width: 70px;
  margin-left: 35px;
  border-style: none;
  border-radius: 25px;
  color: white;
  background-color: rgb(78, 78, 249);
  padding-left: 10%;
  padding-right: 10%;
}

.submit-approve:hover {
  background-color: rgb(122, 122, 249);
  cursor: pointer;
}


.p_text {
  display: block;
  padding: 0 10px;
  font-size: 1.75vw;
  font-weight: 500;
}

.d_text {
  display: none;
  padding: 0 10px;
  font-size: 1.25vw;
  font-weight: 300;
  white-space: nowrap; /* Add this to initially hide the overflow text */
  overflow: hidden; /* Add this to initially hide the overflow text */
  text-overflow: ellipsis; /* Add this to show an ellipsis */
  width:50%;
}



.view {
  position: absolute;
  flex-direction: row;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
  font-size: 1rem;
}

.view:hover {
  color: rgb(255, 255, 255, 0.75);
  
}

.thumbnailText {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #e4e4e4;
  transition: all 0.5s ease;

}
.thumbnailText:hover .more{
  display: none;

}
.thumbnailText:hover .d_text{
  white-space: normal; 
  overflow: hidden; 
  width: 80%;
  text-overflow: ellipsis; /* Add this to show an ellipsis */
  display: block;


}
.thumbnailText:hover {
 height: 87%;
 

}

.thumbnailText:hover .view_text{
  display: none;
  transform: translatex(-100);
  transform: all 10s ease;
  
}


