 .footer-page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c3438;
  height: 10vh;
  padding: 0 1rem;
  color: #ffffff;
}

.footer-hyper-links {
  color: #ffffff;
  margin: 0 0.5rem;
  text-decoration: none;

}

.footer-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-connect-info {
  display: flex;
  align-items: center;
}

.footer-footer-titles {
  margin-right: 1rem;
  font-weight: bold;
}

.footerText {
  font-size: 1vw;
}

.footerHeaders {
  font-size: 1.5vw;
  margin-top: 0.5%;
}
@media (max-width: 700px) {
  .footerHeaders{ 
    font-size: 6vw; /* Increase text size for screens smaller than 700px */
    margin-top: 1%;
  }
  .footerText {
    font-size: 5vw; /* Increase text size for screens smaller than 700px */
  }
}