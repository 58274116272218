.aboutPage {
  background-image: url("../Images/paperback.jpg");
  background-position: center;
  height: auto;
  width: 100vw;
  padding-bottom: 30vw;
}

.headText {
 background-color: white; 
  text-align: center;
  font-size: 36px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 8vw;
}

.headerImage{
  background-image: url("../Images/purple-background.png");
  background-position:  center;
  background-size: 30vw;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right:auto;
  height: 25vw;
  padding-top:2vh;
}

.headerImageAbout {
  background-image: url("../Images/foto5x300.png");
  background-position: center;
  width: 100%;
  height: 350px;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  border-bottom: 3px solid rgb(1, 99, 5);
}

.mainText {
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.storyButton {
  margin-top: 2vw;
  width: 8vw;
  height: 2vw;
  font-size: 0.8vw;
}

.storyButton:hover {
  cursor: pointer; /* Change to the appropriate cursor style */
}

.commentForm {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced and consistent gap */
  width: 40vw; /* Full width on smaller screens */
  height: auto;
  background-color: transparent;
  padding: 5%; /* Reduced padding */
  box-shadow: 0 0 5vw 0 rgb(95, 27, 96);
  border-radius: 2vw;
}

@media (max-width: 700px) {
  .commentForm {
    width: 90vw;
  }

  .aboutPage {
    padding: 5vw;
  }
  body {
    max-width: 100%;
  }

  .headText {
    width: 80%;
  margin-bottom: 3vw;
  }
  .headerImage{
    background-image: url("../Images/purple-background.png");
    padding-bottom: 5vh;
    margin-bottom: 10vh;
    background-size: 30vh;
    background-position: 'right';
  }
  

}

.commentFormHeader {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 36px;
  justify-content: center;
  text-align: center;
}

.formInput {
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  color: rgb(35, 33, 34);
  width: 100%;
  border: 0.1px solid black;
  font-size: 16px;
  font-family: "Trebuchet MS", sans-serif;
  height: 50px; /* Fixed height for better control */
  padding: 10px; /* Consistent padding */
}

.formTextArea {
  border-radius: 2vw;
  background-color: rgb(255, 255, 255);
  color: rgb(35, 33, 34);
  width: 100%;
  border: 0.1px solid black;
  font-size: 16px;
  font-family: "Trebuchet MS", sans-serif;
  resize: none;
  height: 50px; /* Fixed height for better control */
  padding: 10px; /* Consistent padding */
  height: 150px;
}

.formSubmitButton {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  background-color: rgba(95, 27, 96, 0.728);
  height: 4vw;
  border: none;
  padding-left: 2vw;
  padding-right: 2vw;
  font-size: 2vw;
  display: block;
  margin-top: 10px;
  font-family: "Trebuchet MS", sans-serif;
}

.formSubmitButton:hover {
  cursor: pointer;
}

.formSubmitButtonValue {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 2vw;
}

.storyButton {
  text-align: center;
  margin-right: auto;

  border-radius: 2vw;
  background-color: rgba(95, 27, 96, 0.559);
  box-shadow: 0 0 5vw 0 rgb(95, 27, 96);
  width: 15%;
  height: 2.5vw;
  border: none;
  font-size: 1.5vw;
  display: block;
  margin-top: 5vw;
  margin-bottom: 2vw;
  font-family: "Trebuchet MS", sans-serif;
}
