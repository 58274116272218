.hide {
    display: none;
  }


  .sidenav {
    width: 40vw;
    position: fixed;
    left: -40vw; /* Start off-screen to the left */
    top: 0;
    height: 100%;
    background-color: #585656; /* Example background color */
    overflow-x: hidden; /* Prevent horizontal scrollbar */
    transition: transform 0.5s ease; /* Smooth transition for transform property */
    z-index: 1001; /* Ensure sidenav is above most other content */
  }

  .sidebutton {
    width: 60vw;
    position: fixed;
    right: -60vw; /* Start off-screen to the left */
    top: 0;
    height: 100%;
    background-color:transparent; /* Example background color */
    overflow-x: hidden; /* Prevent horizontal scrollbar */
    transition: transform 0.5s ease; /* Smooth transition for transform property */
    z-index: 1000; /* Ensure sidenav is above most other content */
    border: none;
  }

  .sidebutton.open {
    transform: translateX(-60vw); /* Move into view */
  }
  
  .sidenav.open {
    transform: translateX(40vw); /* Move into view */
  }

  .navbarLink {
    transition: color 0.3s, font-weight 0.3s;
    position: relative; /* Added */
  }
  
  .navbarLinkText {
    color: #00265b;
  }
  
  
  .navbarLink::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
  .nav_list{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    list-style: none;
    padding: 0;
  }
  .navbarLink:hover {
    color: #fff;
  }
  
  .navbarLink:hover::after {
    visibility: visible;
    margin-left: 2.5%;
    width: 95%;
  }
  
  .navbar_logo{
    height: 3vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw; 
    cursor: pointer;
  }
  .rainbow_border {
    /* Initial border setup */
    border: 5px solid;
    text-align: center;
    border-radius: 2%;
    /* Animation */
    animation: rainbow-animation 8s infinite linear;
    padding: 0.5vw;
    
  }
  
  @keyframes rainbow-animation {
    0% {border-color: red;}
    14% {border-color: orange;}
    28% {border-color: yellow;}
    42% {border-color: green;}
    57% {border-color: blue;}
    71% {border-color: indigo;}
    85% {border-color: violet;}
    100% {border-color: rgb(224, 48, 89);}
  }
  
  
  
  .logoText{ 
    text-align: center;
    justify-content: center;
    font-size: 1.25vw;
    color:black;
    font-weight: bold;
    margin-left: 1.5vw;
  
   
  }
  
  .logoText:hover{ 
    color: black;
   
  }
  
  .nav_item {
    margin-top: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw;
    font-weight: 500;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s, font-weight 0.3s;
  
  }
  
  .nav_item:hover {
    color: #fff;
    font-weight: 700;
  }
  
  .nav_link{
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    font-size: 2vw;
    font-weight: 500;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s, font-weight 0.3s;
  }
  
  .icon{
   
    color: black;
    font-size: 2.5vw;
    margin-right: 0.5vw;
    transition: color 0.3s, font-weight 0.3s;
  
  }
  
  
  @media (max-width: 700px) {
  
  .navbar_logo{
      height: 12vw;
      padding-left: 2vw;
      padding-right: 2vw; 
      cursor: pointer;
    }
  .logoText{ 
    
    font-size: 5.5vw;
    margin-left: 0.5vw;
  }
  .nav_item {
    font-size: 5vw;
  }
  .nav_link {
    margin-top: 3vh;
    font-size: 7vw;
  }
  
  .icon{
    font-size: 5vw;
    height: 3vw;
  }
  }