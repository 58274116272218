.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99999;
}

/* Centers the modal in the window */
.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalBox {
  position: relative;
  height: 20vw;
  width: 25vw;
  background-color: white;
  color: rgb(15, 16, 19);
  border-radius: 20pt;
}

/* Centers the content inside of the window */
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  color: black;
  font-size: 16px;
  gap: 1vh;
}

.submitButton {
  border-radius: 10pt;
  font-size: 1vw;
  margin-top: 5%;
  padding: 2%;
}

.textInput {
  width: 70%;
  height: 3vh;
  border-radius: 10pt;
  border: 1px solid black;
  padding: 2%;
  font-size: 1vw;
}
