/* styles relating to the password protected page */

.passwordPage {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.storyHeader {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.headerBackground{
  
  background-image: url("../Images/yellow-background.png");
  background-position: bottom center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 25vw;
  padding-bottom: 5%;

}

.passwordForm {
  font-size: clamp(16pt, 2vw, 2em);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-hero {
  font-size: clamp(36pt, 5vw, 5em);
}

.password-text {
  /* empty for now - not currently needed due to inheritence */
}


.password-input {
  width: 30vw;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.password-submit {
  padding: 10px 20px;
  font-size: clamp(10pt, 0.8vw, 2em);
  border-radius: 5px;
  color: #000000;
  background-color: #f6f6f6;
  border: none;
  cursor: pointer;
}

/* rest of the styles */

.images {
  margin-top: 8px;
  vertical-align: middle;
  width: 200px;
  height: 200px;
}

.galleryTitle {
  width: 800;
  height: auto;
}

.title {
  margin-top: 100;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  padding-bottom: 5%;
  visibility: hidden;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 4px;
  width: 60%;
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.imageContainer {
  margin-top: 160px;
  display: inline-block;
}

.headerImage {
  background-image: url("../Images/foto1x300.png");
  background-position: center;
  width: 100%;
  height: 350px;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  border-bottom: 3px solid rgb(1, 99, 5);
}

.createStoryButton {
  z-index: 1000;
  background-color: #ffb100;
  color: rgb(15, 16, 19);
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1%;
  border-radius: 25px;
  cursor: pointer;
  transition-delay: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10%;
  bottom: 5%;
}

.createStoryButton:hover {
  background-color: rgb(255, 177, 0, 0.5);
  transition-delay: 0.1s;
}

.digital-story-gallery-title {
  color: black;
  font-size: 3rem;
  padding: 1vw;
}

/* Medium screens */
@media screen and (min-width: 600px) {
 
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}


@media (max-width: 700px) {
.storyHeader {
  font-size: 15vw;
}


.headerBackground{
  
  background-image: url("../Images/yellow-background.png");
  background-position: bottom center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 50vw;
  padding-bottom: 5%;

}
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}