.uploadMediaBox {
  /* width: fit-content; */
  background-color: white;
  display: flex;
  flex-direction: column;

  overflow: hidden;
  z-index: 0;

  border-radius: 5px;
}

.orDivision {
  flex: 0;
  color: rgb(15, 16, 19);
  padding-left: 8%;
  padding-right: 8%;
}

.half {
  --font-size: 0.8em; /* set the font size variable for the parent element */

  flex: 1;
  font-size: var(--font-size);
  position: relative;
  z-index: 0;
}

.fileUpload {
  --font-scale: 0.8; /* set the font scale variable for the child element */

  font-size: calc(
    var(--font-scale) * var(--font-size)
  ); /* set the font size of the child element based on the parent's font size */
  font-size: calc(
    0.02rem + 0.8vw
  ); /* override the above value with a dynamic font size */
  border-radius: 0px;
  background-color: rgb(255, 177, 0);
  color: rgb(15, 16, 19);
  border: none;
  cursor: pointer;
  font-weight: 600;
  padding: 2%;
  white-space: nowrap;
  width: 80%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fileUpload:hover {
  background-color: rgb(255, 177, 0, 0.5);
}

.fileUpload:active {
  background-color: rgb(255, 177, 0, 0.1);
}

.youtubeInputContainer {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.uploadIcon {
  height: 2vw;
  resize: horizontal;
  flex: 0;
}

.youtubeInput {
  flex: 1;
  font-size: 16px;
  border-radius: 0px;
  padding: 2pt;
  margin: 0;
  margin-left: 8px;
  border: 2px solid rgb(15, 16, 19);
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 2px;
  background: rgb(15, 16, 19);
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

/*  upload media popup   */

.mediaComponent {
  background: white;
  width: 10vw;
  height: 10vw;
}

.popupOverlay {
  position: fixed;
  top: 10vw;
  left: 26vw;
  width: 50vw;
  height: 30vw;
  background-color: gray;
  display: flex;
  align-items: center;
  z-index: 9999;
}
.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
