/*
this class manages the style for the story viewer after stories have been submitted
will need to update this so that the images do not escape the slides, and this is 
responsive for different screen sizes

currently though this is pretty good :) 

will need to change slide 3 so it looks like the thumbnail.

might need multiple image display classes - TBD
*/

/* 
observations:

panel 0, 2 are good.

panel 1 does not fit content to text box

panel 3 images are too big

panel 4 images are too far left, spacing is off, too big.

*/

/*

panel 4 is fixed at least on 1080p screens
 
*/

.storyIntro {
 
  background-color: black;
  color: white;
  text-align: center;
  padding-top: 48vh;
  height: 130vh;
  margin-right: auto;
  margin-left: auto;
  opacity: 0.9;
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 100% 40px  rgba(0, 0, 0, 0.979); /* Example shadow */
}
 
.closeButton{
  position: fixed;
  top: 13%;
  right: 2%;
 
  padding: 10px;
  font-size: 1.5vw;
  color:rgba(119, 119, 119, 0.979);;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  box-shadow: 0 0 5px 5px  rgba(119, 119, 119, 0.979); /* Example shadow */
  border-radius: 10%;
  padding-left: 10px;
  padding-right: 10px;
}


.storyContainer {
  background-size: cover; /* or 'contain', depending on your need */
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.containerFull {
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.panelImage {
  width: 40vw;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  border: solid;
}

.panelImageFive {
  width: 45vw;
  height: auto;
  margin-top: -7vh;
  margin-bottom: -7vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  border: solid;
}

.panelImageFour {
  object-fit: fill;
  width: 13vw;
  height: auto;
  margin-top: -8vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  border: solid;
}

.panelThreeTiltedLeft {
  width: 20vw;
  height: auto;
  border-radius: 25px;
  margin-top: 8vh;
  margin-bottom: 7vh;
  margin-left: 2.4vw;
  border: solid;
  transform: rotate(-25deg);
}

.panelThreeTiltedRight {
  float: right;
  width: 20vw;
  height: auto;
  margin-top: 8vh;
  margin-left: 1.4vw;
  margin-bottom: 7vh;
  border-radius: 25px;
  border: solid;
  transform: rotate(25deg);
}

.panelImageTiltedLeft {
  width: 13vw;
  height: auto;
  border-radius: 25px;
  margin-top: 10%;
  margin-left: 2.4vw;
  border: solid;
  transform: rotate(-25deg);
}

.panelImageTiltedRight {
  width: 13vw;
  height: auto;
  margin-top: 10%;
  margin-left: -0.2vw;
  border-radius: 25px;
  border: solid;
  transform: rotate(25deg);
}



/* Constants */

.arrowDown {
  font-size: 4vw;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -20%);
  text-align: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.storyHeadline {
  font-size: 4vw;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translate(-50%, -3vw);
  text-align: left;
  color: white;
}

.storySubHeadline {
  width: 100%;
  top: -4vw;
  font-size: 1.5vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 5%);
  text-align: left;
  color: white;
  padding-bottom: 10%;
}

.panelDescription {
  width: 100%;
  font-size: 1.5vw;
  text-align: left;
  color: white;
  padding-bottom: 5%;
}

.heading {
  text-align: center;
  font-size: 36px;
}

.citations {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  overflow: auto;
  max-height: fit-content;
  word-wrap: break-word;
  color: white;
  /* background: rgba(0, 0, 0, 0.6); 50% transparent black */
  min-width: 40vw;
  max-width: 50vw;
  }


/** Used for the Headline / Description panel */
.templateForm1 {
  background-color: rgb(15, 16, 19, 0.85);
  padding: 10%;
}

/** Used for a 2-column panel */
.templateForm2 {
  background-color: rgb(15, 16, 19, 0.85);
  align-items: center;
  column-gap: 2vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.loading {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  font-size: 3vh;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.templates {
  padding-bottom: 20vh;
  background-size: contain; /* or 'contain' depending on your preference */
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 
Similar to  the previously used 'universalPanelContainer' but without rounded corners
*/
.panelContainer {
  /* center panel horizontally */
  margin-left: auto;
  margin-right: auto;

  /* add offset to the top - debateable if this is required*/
  

  /* calculate height and aspect ratio */
  height: fit-content;
  /* width */
  width: 60vw;

  /* offset from bottom */
  margin-bottom: 5vh;

  /* standard bg color, maybe can be edited with js in future */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15vw;
}

.panelContainerFirstAuthor {
  /* center panel horizontally */
  padding-left: 40vw;
  padding-right: 40vw;

  /* add offset to the top - debateable if this is required*/
  

  /* calculate height and aspect ratio */
  height: fit-content;
  /* width */


  /* offset from bottom */
  padding-bottom: 5vh;
  padding-top: 5vh;

  /* standard bg color, maybe can be edited with js in future */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
 
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-top: 5px solid black;
 
  
  
}

.panelContainerAuthor {
  /* center panel horizontally */
  padding-left: auto;
  padding-right: auto;

  /* add offset to the top - debateable if this is required*/
  

  /* calculate height and aspect ratio */
  height: fit-content;
  /* width */


  /* offset from bottom */
  padding-bottom: 5vh;
  padding-top: 5vh;

  /* standard bg color, maybe can be edited with js in future */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
}

.CitationPanelContainer {
  /* center panel horizontally */
  padding-left: auto;
  padding-right: auto;

  /* add offset to the top - debateable if this is required*/
  

  /* calculate height and aspect ratio */
  height: fit-content;
  /* width */


  /* offset from bottom */
  padding-bottom: 5vh;
  padding-top: 5vh;

  /* standard bg color, maybe can be edited with js in future */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
 
}

.largeImagePreview {
  max-width: 60vw;
  max-height: 35vw;
  height: auto;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border-radius: 5px;
}

.largeImageParent {
  display: flex;
  align-items: center;  
}

.date {
  font-size: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.6); /* 50% transparent black */
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
  text-align: center;

}


.text {
  color: white;
  background: rgba(0, 0, 0, 0.6);  /* 50% transparent black */
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 5px;
  overflow-y: auto;
  word-wrap: break-word;
  max-width: 50vw;
}

.sideBySide {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.smallCaption {
  margin: 1%;
  font-size: 24px;
  max-height: fit-content;
  word-wrap: break-word;
  color: white;
  background: rgba(0, 0, 0, 0.6); /* 50% transparent black */
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 50vw;
  
}

.smallCaption_noBackground {
  margin: 1%;
  font-size: 24px;
  max-height: fit-content;
  overflow-y: auto;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 50vw;
 
}

.nudgeLeft {
  margin-left: 3vw;
}

.sideImageParent {
  width: 20vw;
  min-height: 20vw;
  max-height: 20vw;
}

.sideImage {
  width: 20vw;
  height: 20vw;
}

.sideCaption {
  width: 15vw;
  height: 21vw;
}

.authorImageParent {
  display: flex;
  align-items: center;
}


.authorImage {
  width: 15vw;
  width: 15vw;
  height: 15vw;
  height: 15vw;
  border-radius: 50%;
  
  
}

.authorCaption {
  margin: 1%;
  font-size: 24px;
  max-height: 20vw; /* or your preferred height */
  overflow-y: auto;
  color: white;
  background: rgba(0, 0, 0, 0.6); /* 50% transparent black */
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  width: 50vw;
  font-style: italic;

}


.citationCaption{
  margin: 1%;
  font-size: 24px;
  max-height: fit-content;
  overflow: auto;
  word-wrap: break-word;
  color: white;
  background: rgba(0, 0, 0, 0.6); /* 50% transparent black */
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 60vw;
  min-width: 55vw;
 


}



.imageTrash1 {
  position: relative;
  margin-top: -60%;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 202;
}

/* onhover for trash icon */
.imageTrash1 :hover {
  cursor: grab;
}

.arrow {
  margin-top: 25vh;
  font-size: 60px; /* Adjust size as needed */
  text-align: center;
  transition: opacity 0.3s ease;
}


.imageTrash2 {
  position: relative;
  margin-top: -102%;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 202;
}

/* onhover for trash icon */
.imageTrash2 :hover {
  cursor: grab;
}

/*
copied from old trash button implimentation
not sure if this will have to be changed or not
will be tested for in testing */
.trash {
  position: relative;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  width: 1vw;
  /* Adjust width as needed */

  /* Positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 201;
}

.trash:hover {
  cursor: grab;
}

.trash2 {
  position: relative;
  color: black;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  width: 1vw;
  /* Adjust width as needed */

  /* Positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 201;
}

.trash2:hover {
  cursor: grab;
}

.black {
  color: black;
}

.panel5Parent {
  height: inherit;
  margin-top: 18vw;
}

/* style classes for the parent divs for the rows */
.topRow {
  margin-top: -20vw;
  height: 33.3%;
  /* bgcolor for testing only */
  background-color: white;
}

.midRow {
  height: 33.3%;
  background-color: white;
}

.botRow {
  height: 33.3%;
  background-color: white;
}

/* style classes for the parent divs for media in the middle row */

.midRowLeft {
  margin-left: 0vw;
  max-width: 49.9%;
  min-width: 49.9%;
  min-height: 10vw;
  max-height: 10vw;
}

.midRowRight {
  margin-left: 49.76%;
  margin-top: -10vw;
  max-width: 49.9%;
  min-width: 49.9%;
  min-height: 10vw;
  max-height: 10vw;
}

/* style classes for the parent divs for media in the top and bottom row */

.botRowLeft {
  margin-left: 0vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

.botRowMid {
  margin-left: 33.2%;
  margin-top: -10vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

.botRowRight {
  margin-left: 66.56%;
  margin-top: -10vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

/* panel 5 inner image / media classes (affects mediapreview component) */

.topRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.midRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.botRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.imageTrash3_1 {
  position: relative;
  color: yellow;
  margin-top: -2.2vw;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 202;
}

/* onhover for trash icon */
.imageTrash3_1:hover {
  cursor: grab;
}

.moveLeft2 {
  align-items: center;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 100%;
  gap: 5%;
}
.previous {
  margin-left: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
  background-color: #414c52;
  color: white;
  font-style: italic;
  padding: 0.5%;
  border-radius: 20%;
  border: 2px solid white;
}

.next {
  margin-right: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
  background-color: #414c52;
  color: white;
  font-style: italic;
  padding: 0.5%;
  border-radius: 20%;
  border: 2px solid white;
}

.next:hover {
  background-color: #414c52;
  color: white;
}

.previous:hover {
  background-color:  #414c52;
  color: white
}

@media screen and (max-width: 600px) {
  .panelContainer {
    width: 90vw;
    height: fit-content;
    margin-bottom: 10vh;
  }

  .largeImageParent {
    width: 90vw;
    max-height: 90vw;
  }

  .largeImagePreview {
    max-width: 90vw;
    max-height: 50vw;
  }

  .authorCaption{
    font-size: 1rem;
  }

  .authorImageParent {
    width: 90vw;
    max-height: 90vw;
  }
  .authorImage{
    max-width: 40vw;
    max-height: 40vw;
  }

  .smallCaption {
    font-size: 1rem;
  }

  .heading {
    font-size: 1.5rem;
  }



  .previous {
    font-size: 1rem;
  }
  .next {
    font-size: 1rem;
  }
  .closeButton{
    font-size: 1rem;
    top:12%;
  }

}
