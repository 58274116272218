/* Chip styles */
.tag-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px;
  justify-content: center;
}

.background {
  background-image: url("../Images/paperback.jpg");
  background-position: center;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

.storyHeader{
  margin-left: 'auto';
  margin-right: 'auto';
}

.chip {
  padding: 8px 12px;
  border-radius: 25px;
  background-color: #e0e0e0;
  border: 2px solid transparent; /* Adjust border color as needed */
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, border-color 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* x-offset, y-offset, blur-radius, spread-radius, color */
}

.chip:hover {
  background-color: #d5d5d5; /* Slightly darker on hover */
}

.chip-selected {
  background-color: #03a9f4; /* Active color for selected chip */
  color: white;
  border-color: #0277bd; /* Darker border for selected chip */
}

.imgFit {
  object-fit: cover;
}

.imgList ul li {
  float: left;
  width: 25%;
  margin-bottom: 3rem;
  justify-content: center;
}

.imgList ul li a {
  list-style: none;
  width: 20vw;
  height: 20vw;
  display: block;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transition: border 0.5s ease;
  transition: transform 0.5s ease;
  border: 1px solid transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); /* x-offset, y-offset, blur-radius, spread-radius, color */
  gap: 10px;
}

.clearfix{ 
  list-style-type: none;
}

.imgList ul li a img {
  width: 100%;
  height: 100%;
}
.imgList{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.thumbnailText {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #e4e4e4;
  transition: all 0.5s ease;

}
.thumbnailText:hover .more{
  display: none;

}
.thumbnailText:hover .d_text{
  white-space: normal; 
  overflow: hidden; 
  width: 80%;
  text-overflow: ellipsis; /* Add this to show an ellipsis */
  display: block;


}
.thumbnailText:hover {
 height: 87%;
 

}

.thumbnailText:hover .view_text{
  display: none;
  transform: translatex(-100);
  transform: all 10s ease;
  
}


.p_text {
  display: block;
  padding: 0 10px;
  font-size: 1.75vw;
  font-weight: 500;
}

.d_text {
  display: none;
  padding: 0 10px;
  font-size: 1.25vw;
  font-weight: 300;
  white-space: nowrap; /* Add this to initially hide the overflow text */
  overflow: hidden; /* Add this to initially hide the overflow text */
  text-overflow: ellipsis; /* Add this to show an ellipsis */
  width:50%;
}



.view {
  position: absolute;
  flex-direction: row;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
  font-size: 1rem;
}

.view:hover {
  color: rgb(255, 255, 255, 0.75);
  
}

.view img {
  width: 35px !important;
  vertical-align: 1rem;
}

@media (max-width: 640px) {
  .contentBox {
    width: 95%;
  }

  .imgList ul li {
    width: 90%;
  }

  .imgList ul li a {
    width: 2rem;
    height: 2rem;
  }

  .botList img {
    vertical-align: -0.25rem;
  }

  .ppt {
    font-size: 0.3rem;
  }
}

/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
@media (max-width: 648px) {
  /*Gallery text and underline*/
  .galleryTitle {
    font-size: 30pt;
  }

  .underlineGallery {
    margin-top: 1vw;
    width: 14vw;
    height: 5px;
    background-color: #7c3224;
    border: none;
    border-radius: 50px;
  }

  /*About text and underline*/
  .aboutTitle {
    font-size: 30pt;
    margin-left: 5vw;
  }

  .underlineHome {
    margin-left: 5vw;
    margin-bottom: 8vw;
    margin-top: 1vw;
    width: 14vw;
    height: 5px;
    background-color: rgb(124, 50, 36);
    border: none;
    border-radius: 50px;
  }

  .img {
    display: block;
    width: 100%;
    margin-top: 5vw;
  }

  .imgList ul li a {
    width: 2rem;
    height: 2rem;
    display: block;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: border 0.5s ease;
    transition: transform 0.5s ease;
    border: 4px solid transparent;
  }

  .mainButtonsContainer {
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    float: right;
    width: 2rem;
    text-align: center;
    position: absolute;
    top: 14%;
    left: 90%;
  }

  .mapButton i,
  .galleryButton i {
    font-size: 0.35rem !important;
    color: #ffffff;
  }

  .mapButton {
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border-color: rgb(255, 255, 255);
    cursor: pointer;
    border: none;
    display: block;
    background-image: linear-gradient(
      170deg,
      #092a2f,
      rgb(9, 42, 47, 0.75),
      rgb(9, 42, 47, 0.75)
    );
    background-color: transparent;
  }

  .right {
    overflow: hidden;
    width: 90vw;
    text-align: left;
    font-size: 3vh;
    padding-left: 8vh;
    padding-top: 4vh;
    /*space between title and pargraph*/
  }

  .left {
    width: 95vw;
    padding-left: 7.7vh;
    position: relative;
  }

  /* Not used */
  /* .leftText {
		text-align: left;
		font-size: 4vh;
		font-weight: 200;
		padding-right: 1%;
		padding-left: 0;
	} */

  .commentFormTitle {
    font-size: 30pt;
    text-align: left;
  }

  .commentForm {
    padding: 10px;
    padding-left: 10px;
    padding-top: 20px;
  }

  .underlineHomeComment {
    margin-left: 5vw;
    margin-bottom: 1vw;
    margin-top: 1vw;
    width: 14vw;
    height: 5px;
    background-color: rgb(124, 50, 36);
    border: none;
    border-radius: 50px;
  }

  .formSubmitButton {
    padding-left: 20px;
    width: auto;
    border-radius: 15px;
    position: relative;
    left: 155px;
    font-size: 2vh;
  }

  body {
    font-size: 0.16rem;
  }

  .formInput {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: none;
  }

  .formInputDescription {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: none;
  }

  .p_text {
    display: block;
    padding: 0 10px;
    font-size: 6pt;
    font-weight: 300;
  }

  .d_text {
    display: block;
    padding: 0 10px;
    font-size: 8pt;
    font-weight: 300;
  }

  .view {
    position: absolute;
    bottom: 5.5px;
    right: 0.008rem;
    font-weight: bold;
    font-size: 0.15rem;
  }

  .view img {
    width: 25px !important;
    vertical-align: -0.1rem;
  }
}

/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
@media (max-width: 648px) {
  .view {
    font-size: 1rem;
  }

  .chip {
    font-size: 1rem;
  }

  .imagePreviewBoxGallery {
    width: 100px;
    height: 100px;
    border-radius: 25px;
    /* float: left; */
  }

  .textWrapper {
    margin-left: 1vw;
    width: 110vw;
    padding-left: 10vw;
  }

  .imgList ul li a {
    width: 85vw;
    height: 40vh;
  }

  .imagePreviewBoxGallery {
    padding-top: 300px;
    padding-left: 10px;
    width: 200px;
    height: 200px;
    border-radius: 25px;
  }
  .p_text {
    display: block;
    padding: 0 10px;
    font-size: 6em;
    font-weight: 500;
  }

  .d_text {
    display: block;
    padding: 0 10px;
    font-size: 6em;
    font-weight: 300;
  }
}
