/* 
Similar to  the previously used 'universalPanelContainer' but without rounded corners
*/
.panelContainer {
  /* center panel horizontally */
  margin-left: auto;
  margin-right: auto;

  /* calculate height and aspect ratio */
  max-height: 45vw;
  /* width */
  max-width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  position: relative;
  border-radius: 5px;

  /* offset from bottom */
  margin-bottom: 10vh;
  /* standard bg color, maybe can be edited with js in future */
  background-color: rgba(0, 0, 0, 0.5);

}

.characterCounter{
  display: block;
  padding: 8px;
  padding-left: 4%;
  border-radius: 25px;
  border: none;
  font-size: 1.1vw;
  font-family: "Nunito", sans-serif;
  width: 16vw;
  height: 1.85vw;
  text-align: right;
  color:white;
  padding-top: 0;
  margin-top: 0;

}

.largeImagePreview {
  max-width: 60vw;
  max-height: 35vw;
  object-fit: cover;
  background-repeat: no-repeat;
}

.largeImageParentNoBackground {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  max-width: 60vw;
  max-height: 35vw;
}

.largeImageParent {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  max-width: 60vw;
  max-height: 35vw;
  border: 1px solid black;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
}

.storyCitations {
  font-size: 0.5vw;
  height: 6vh;
  width: 35vw;
  font-style: italic;
}

.storyTitle {
  font-size: 3vw;
  height: 20vh;
  width: 35vw;
}

.sideBySide {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.smallCaption {
  width: 35vw;
  height: 20vh;
  font-size: 1rem;
}

.imageCaption {
  width: 35vw;
  height: 10vh;
  font-size: 1rem;
  border-radius: 5px;
}

.moveLeft {
  margin-left: 20vw;
  margin-top: -21.5vw;
}

.moveLeft2 {
  margin-left: 25vw;
  margin-top: -20vw;
}

.nudgeLeft {
  margin-left: 3vw;
}

.sideImageParent {
  width: 20vw;
  min-height: 20vw;
  max-height: 20vw;
}

.sideImage {
  max-width: 35vw;
  max-height: 35vw;
  object-fit: cover;
  background-repeat: no-repeat;
  border: 1px solid black;
}

.authorImage {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.sideCaption {
  width: 10vw;
  height: 20vw;
  font-size: 1rem;
  border-radius: 5px;
}

.authorCaption {
  width: 25vw;
  height: 15vw;
  font-size: 1.5rem;
  border-radius: 5px;
  font-style: italic;
}

.imageTrash1 {
  position: absolute;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  width: 1vw;

  /* Positional, appearance, and clickability */
  top: 1vw;
  left: 5vw;
  background-color: transparent;
  border: 0;
  z-index: 201;
  color: white;
}

/* onhover for trash icon */
.imageTrash1 :hover {
  cursor: grab;
}

.imageTrash2 {
  position: relative;
  margin-top: -102%;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 202;
}

/* onhover for trash icon */
.imageTrash2 :hover {
  cursor: grab;
}

/*
copied from old trash button implimentation
not sure if this will have to be changed or not
will be tested for in testing */
.trash {
  position: absolute;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  width: 1vw;

  /* Positional, appearance, and clickability */
  top: 0;
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 201;
  color: white;
}

.trash:hover {
  cursor: grab;
}

.trash2 {
  position: relative;
  color: black;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  width: 1vw;
  /* Adjust width as needed */

  /* Positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 201;
}

.upArrow {
  position: absolute;
  padding: 10px;
  height: calc(1.4vw);
  width: 1vw;

  /* Positional, appearance, and clickability */
  top: 1vw;
  right: 1vw;
  background-color: transparent;
  border: 0;
  color: white;
}
.upArrow:hover {
  cursor: grab;
}
.downArrow {
  position: absolute;
  padding: 10px;
  height: calc(1.4vw);
  width: 1vw;
 
  /* Positional, appearance, and clickability */
  bottom: 1.5vw;
  right: 1vw;
  background-color: transparent;
  border: 0;
  color: white;
}
.downArrow:hover {
  cursor: grab;
}

.trash2:hover {
  cursor: grab;
}

.black {
  color: black;
}

.panel5Parent {
  height: inherit;
  margin-top: 18vw;
}

/* style classes for the parent divs for the rows */
.topRow {
  margin-top: -20vw;
  height: 33.3%;
  /* bgcolor for testing only */
  background-color: white;
}

.midRow {
  height: 33.3%;
  background-color: white;
}

.botRow {
  height: 33.3%;
  background-color: white;
}

/* style classes for the parent divs for media in the middle row */

.midRowLeft {
  margin-left: 0vw;
  max-width: 49.9%;
  min-width: 49.9%;
  min-height: 10vw;
  max-height: 10vw;
}

.midRowRight {
  margin-left: 49.76%;
  margin-top: -10vw;
  max-width: 49.9%;
  min-width: 49.9%;
  min-height: 10vw;
  max-height: 10vw;
}

/* style classes for the parent divs for media in the top and bottom row */

.botRowLeft {
  margin-left: 0vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

.botRowMid {
  margin-left: 33.2%;
  margin-top: -10vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

.botRowRight {
  margin-left: 66.56%;
  margin-top: -10vw;
  max-width: 33.1%;
  min-width: 33.1%;
  min-height: 10vw;
  max-height: 10vw;
}

/* panel 5 inner image / media classes (affects mediapreview component) */

.topRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.midRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.botRowImage {
  max-width: 100%;
  min-width: 100%;
  max-height: 10vw;
  min-height: 10vw;
  object-fit: cover;
  background-repeat: no-repeat;
  box-sizing: border box;
  border: solid;
}

.imageTrash3_1 {
  position: relative;
  color: yellow;
  margin-top: -2.2vw;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 202;
}

/* onhover for trash icon */
.imageTrash3_1:hover {
  cursor: grab;
}
