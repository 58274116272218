/* Each map can be put into a div with specified CSS */
/* .map {
    position: relative;
    top: 200px;
} */

.marker-button {
  background: none;
  border: none;
}

.headerImageMap {
  background-image: url("../Images/foto4x300-2.png");
  background-position: center;
  width: 100%;
  height: 350px;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  border-bottom: 3px solid rgb(1, 99, 5);
}

.mapContainer {
  width: 90vw;
  height: 90vh;
}

.marker-button:hover {
  background-color: transparent;
  background: none;
}

.card-style {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 50%;
}

.contentContainer {
  background-color: aliceblue;
  width: auto;
  height: auto;
}

.card-style .mapboxgl-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;
  border-radius: 10px;
  font-family: "Nunito", sans-serif;
  background-image: linear-gradient(170deg, #092a2f, rgb(0, 0, 0, 0.75));
  background-color: transparent;
}

.card-style .mapboxgl-popup-close-button {
  font-size: 30pt;
  padding-right: 5%;
  padding-left: 5%;
  background-color: rgba(5, 25, 28, 0.5);
  color: #f3f4f4;
  border-bottom-left-radius: 25px;
}

.popupText {
  font-family: "Open Sans", sans-serif;
}

.image-style {
  height: 50px;
  width: 50px;
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 5%;
  object-fit: cover;
  border: 1px solid #f3f4f4;
}

.button1 {
  border: none;
  left: 2px;
}

.button2 {
  border: none;
  left: 6%;
}

@media screen and (max-width: 680px) {
  .mapContainer {
    width: 92vw;
    height: 65vh;
    align-items: center;
  }
}
