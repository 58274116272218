body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw;
  color: black;
  font-family: "Mate";
  background-color: "F1F1F1";
}

html {
  font-size: 16px;
}

@font-face {
  font-family: "Merriweather";
  src: url("./fonts/Merriweather-Regular.ttf") format("truetype");
  font-family: "Merriweather-Bold";
  src: url("./fonts/Merriweather-Bold.ttf") format("truetype");
  font-family: "Mate";
  src: url("./fonts/Mate-Regular.ttf") format("truetype");
}
