/*
Various Authors; refactored by Ruslan Bilotkach 21/03/2023

Stylsheet implemented by:
PanelOptionZero.js
PanelOptionOne.js
PanelOptionTwo.js
PanelOptionThree.js
PanelOptionFourjs
PanelOptionFive.js
CreateStory.js

provides styles for the CreateStory page which allows users to create a slideshow within their web browser, and its panel options

lines 23 -> 37: tags and other non-classes

lines 37 -> 329: createstory.js

lines 331 -> 791: Panels

*/

p {
  margin-top: 1vh;
}

/*------------------------- !!!!!!Classes related to the styling of CreatStory page!!!!!! ------------------------------*/

/*------------------------- classes for password screen -------------------------*/

/* container holding the password textarea */
.passwordContainer {
  margin: auto;
  margin-top: 50vh;
  width: 25vw;
  align-items: center;
  align-self: center;
  text-align: center;
  justify-content: center;
}

/* textarea for password */
.passwordInput {
  display: block;
  padding-left: 0vw;
  margin-right: 1vw;
  padding: 2%;
  margin: auto;
  margin-top: 5%;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  padding-left: 25px;
  width: 16vw;
}

/* END pasword screen classes */

/*------------------------- classes for tooNarrow Screen -------------------------*/

/* Screen which covers page when the viewport is too narrow */
.tooNarrowContainer {
  margin: auto;
  margin-top: 40vh;
  width: 100%;
  align-items: center;
  align-self: center;
  text-align: center;
  justify-content: center;
}

/* END tooNarrow screen classes */

/*------------------------- outermost parent classes for entire page -------------------------*/

/* Container for entire active CreateStory page */
.pageContainer {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* originally parent container for sidebar, now parent of parent of sidebar... unsure if it does anything at all */
.fullContainer {
  margin-top: 0;
  display: grid;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

/* END outermost parent classes */

/*------------------------- classes for left panel creation sidebar -------------------------*/

/* sidebar parent container */
.addPanel {
  position: fixed;
  top: 20vh;
  max-width: 19vw;
  left: 0;
  bottom: 0;
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 1%;
  background-color: rgba(0, 0, 0);
  border-radius: 0;
  border-color: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* buttons for custom backgrounds, found in the sidebar */
.storyBackgroundSelection {
  width: 8vw;
  height: 8vw;
  border-radius: 25px;
  margin-right: 5px;
  cursor: pointer;
  display: inline;

  /* Transparent border so on hover the white border shows up without causing width/heigh to change */
  border: solid;
  border-color: transparent;
}

/* onhover for story background */
.storyBackgroundSelection:hover {
  border-color: white;
}

/* Titles within the sidebar */
.panelCategoryTitles {
  width: 19vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  font-size: 1.5vw;
  color: rgb(255, 255, 255, 0.5);
}

/* horizontal partition between sections within the sidebar */
.panelSelectionPartition {
  color: rgb(255, 255, 255, 0.1);
  border: solid;
  top: 20px;
  margin-top: 1vw;
  margin-bottom: 0.5vw;
}

.loading {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  font-size: 3vh;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

/* input boxes within the sidebar for name, email, title */
.storyBasicInput {
  display: block;
  padding: 8px;
  padding-left: 4%;
  margin-top: 1.2vh;
  border-radius: 25px;
  border: none;
  font-size: 1.1vw;
  font-family: "Nunito", sans-serif;
  width: 17vw;
  height: 0.8vw;
}

/* Panel preview images, found in the sidebar */
.panelPreviewSelection {
  width: 17vw;
  height: calc(15vw * 3 / 4);
  border-radius: 10px;
  display: inline-block;
}

.panelPreviewSelection:hover {
  cursor: pointer;
}

/* Buttons alongside panel preview images, found in the sidebar */
.addPanelButton {
  /* height */
  height: calc(4.2vw * 3 / 4);

  /* width */
  width: 3.3vw;

  /* spacing to the top */
  top: calc(1vw * 3 / 4);
  left: 0.45vw;

  background-color: rgb(131, 35, 102);
  border: none;
  color: white;
  padding: auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2.4vw;
  margin: 2px 2px;
  margin-left: 10px;
  margin-right: 5px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

/* Actions for the panel buttons */
.addPanelButton:hover {
  background-color: rgb(131, 35, 102, 0.5);
}

.addPanelButton:active {
  background-color: rgb(131, 35, 102, 0.25);
}

/* END sidebar classes */

/*------------------------- classes for central panel editor pane -------------------------*/

/* Central container for the panels which are generated by the user */
.panelEditorContainer {
  margin-top: 20vh;
  margin-bottom: 20vh;
  margin-left: 23vw;
  height: 80vh;
  width: 60vw;
  overflow: auto;
  background-color: transparent;
}

/* inner div for the panelEditorContainer which ensures slides are centered */

/* END panel editor classes */

/*------------------------- classes for submit button pane-------------------------*/

/* right "sidebar"/pane holding the submit button */
.submitStoryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  right: 5%;
  transform: translate(-95%, -50%);
}

/* submit button inside of submit story container */
.submitButton {
  position: absolute;
  top: 60px;
  right: -3vw;
  padding: 10px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  width: 140px;
  height: 50px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* save draft button, effectively a clone of the submit button */
.draftButton {
  position: absolute;
  top: 120px;
  right: -3vw;
  padding: 0px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  width: 140px;
  height: 50px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* recover draft button, effectively a clone of the submit button */
.recoverButton {
  position: absolute;
  top: 60px;
  right: -3vw;
  padding: 0px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  width: 140px;
  height: 50px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* edit button, effectively a clone of the submit button */
.editButton {
  position: absolute;
  top: 0px;
  right: -3vw;
  padding: 0px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  display: block;
  width: 135.2px;
  height: 45.2px;
  line-height: 45.2px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.copyButton {

  padding: 10px;
  padding-bottom: 5px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  width: 140px;
  height: 70px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.closeButton {

  padding: 10px;
  padding-bottom: 5px;
  margin: 10px;
  border-radius: 25px;
  border: solid;
  border-color: white;
  width: 140px;
  height: 70px;
  cursor: pointer;
  color: white;
  background-color: rgb(131, 35, 102);
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.popupWindowButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

}

/* submit button actions */
.submitButton:hover {
  background-color: rgb(131, 35, 102, 0.5);
}

.submitButton:active {
  background-color: rgb(131, 35, 102, 0.1);
}

/* draft button actions */
.draftButton:hover {
  background-color: rgb(131, 35, 102, 0.5);
}

.draftButton:active {
  background-color: rgb(131, 35, 102, 0.1);
}

/* recover button actions */
.recoverButton:hover {
  background-color: rgb(131, 35, 102, 0.5);
}

.recoverButton:active {
  background-color: rgb(131, 35, 102, 0.1);
}

/* END submit pane classes */
/*!!!!!!!!!!!!!!!!!! END CreateStory.js styles !!!!!!!!!!!!!!!!!!!*/

/*------------------------- !!!!!!Classes related to the styling of panels!!!!!! ------------------------------*/

/*------------------------- Panel shape and size ------------------------------*/

/* Universal panel container which maintains the size and shape of all panels excluding panel 5 */
.universalPanelContainer {
  /* center div horizontally */
  margin-left: auto;
  margin-right: auto;

  /* add offset to the top */
  margin-top: 20vh;

  /* calculate height and aspect ratio */
  height: calc(38vw * 3 / 4);

  /* width of 55vw */
  width: 45vw;

  /* rounded corners */
  border-radius: 10px;
  /* offset from bottom */
  margin-bottom: calc(5vw * 3 / 4);
  /* standard bg color, maybe can be edited with js in future */
  background-color: rgba(0, 0, 0, 0.85);
}

/* Same as above but for panel 5 - because panel 5 has an image as the background */
.universalPanelContainerP5 {
  /* center div horizontally */
  margin-left: 5.76vw;
  margin-right: auto;

  /* add offset to the top */
  margin-top: 20vh;

  /* calculate height and aspect ratio*/
  height: calc(38vw * 3 / 4);

  /* width of 55vw */
  width: 45vw;

  /* rounded corners */
  border-radius: 10px;
  /* offset from bottom */
  margin-bottom: calc(5vw * 3 / 4);
  /* standard bg color, maybe can be edited with js in future */
  background-color: transparent;
}

/* content parent div for all panels excluding panel 5 */
.universalSlideContent {
  /* match width of outer slide */
  width: 45vw;
  max-width: 45vw;

  /* margins to center conents inside slide */
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  /* content fill less buggy */
  padding: 0;
  border: 0;
  background-color: transparent;

  height: fit-content;
}

/* END panel shape and size */

/*------------------------- Miscelanious inner panel elements ------------------------------*/

/* Trash icon which deletes the active panel */
.trash {
  position: relative;
  padding: 10px;
  height: calc(1.4vw * 3 / 4);
  /* width being small prevents delete page from being clicked from random locations */
  width: 0.2vw;

  /* positional, appearance, and clickability */
  left: 0;
  background-color: transparent;
  border: 0;
  z-index: 201;
}

/* onhover for trash icon */
.trash :hover {
  cursor: grab;
}

/* left content parent container for panel 2 */
.left {
  float: left;
  width: 21vw;
  margin-right: 0vw;
  margin-left: 2vw;
  margin-top: 0;
  overflow: hidden;
  position: relative;
}

/*------------------------- Outer textarea container classes  ------------------------------*/

/* Headline / title for panel 1 */
.headlineInput {
  /* positional styling */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1vh;

  /* size related styling */
  width: 22vw;

  /* calculate height and aspect ratio*/
  height: calc(3vw * 3 / 4);

  /* text and text padding */
  font-size: 1.7vw;
  font-family: "Nunito", sans-serif;
  padding-left: 2%;
  border-radius: 25px;
}

/* Panel 1 textarea */
.textareaContainer {
  width: 36vw;

  /* calculate height and aspect ratio*/
  height: calc(24vw * 3 / 4);
  margin: auto;
  margin-top: calc(3vw * 3 / 4);
  border-radius: 10px;
  border: 0px;
  font-family: "Nunito", sans-serif;
  font-size: 1.5vw;
  overflow: hidden;
}

/* Panel 2 textarea */
.textareaContainerp2 {
  max-width: 21vw;
  height: calc(30vw * 3 / 4);
  margin: auto;
  margin-top: calc(1.2vw * 3 / 4);
  margin-right: 1.5vw;
  border-radius: 10px;
  border: none;
  font-family: "Nunito", sans-serif;
  font-size: 1.5vw;
  overflow: hidden;
}

/* Panel 0 textarea */
.textareaContainerCentered {
  width: 36vw;
  height: calc(29vw * 3 / 4);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(1.6vw * 3 / 4);
  border-radius: 10px;
  border: none;
  font-family: "Nunito", sans-serif;
  font-size: 1.5vw;
  overflow: hidden;
}

/* Panel 3 textarea */
.d3container {
  width: 26vw;

  /* calculate height and aspect ratio*/
  height: calc(11vw * 3 / 4);
  margin: auto;
  margin-top: calc(1vw * 3 / 4);
  border-radius: 10px;
  border: 0px;
  font-family: "Nunito", sans-serif;
  font-size: 1.5vw;
  overflow: hidden;
}

/* Panel 4 textarea */
.d4container {
  width: 26vw;

  /* calculate height and aspect ratio*/
  height: calc(11vw * 3 / 4);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(3vw * 3 / 4);
  border-radius: 10px;
  border: 0px;
  font-family: "Nunito", sans-serif;
  font-size: 1.5vw;
  overflow: hidden;
}

/* Panel 5 textarea */
.d5container {
  position: relative;
  width: 18vw;

  /* calculate height and aspect ratio*/
  height: calc(17vw * 3 / 4);
  margin-left: 25vw;
  margin-top: calc(16.1vw * 3 / 4);
  border-radius: 10px;
  border: 2px solid black;
  font-family: "Nunito", sans-serif;
  font-size: 1.7em;
  overflow: hidden;
  z-index: 100;
}

/* END outer textarea classes */

/*------------------------- inner textarea classes and their scrollbars ------------------------------*/

/* inner textarea + scrollbar for panels 0, 1, 2, 5 */
.textareaInput {
  display: block;
  resize: none;
  box-sizing: border-box;
  width: 100%; /* set the width to 100% of the container */
  height: 100%; /* set the height to 100% of the container */
  padding: 2%;
  border: none;
  font-family: inherit;
  font-size: inherit;
  overflow-y: scroll; /* make only the textarea scrollable */
  z-index: 10;
}

/* inner textarea + scrollbar for panels 3, 4 */
.description3 {
  display: block;
  resize: none;
  box-sizing: border-box;
  width: 100%; /* set the width to 100% of the container */
  height: 100%; /* set the height to 100% of the container */
  padding: 3%;
  border: none;
  font-family: inherit;
  font-size: inherit;
  overflow-y: scroll; /* make only the textarea scrollable */
}

/* scrollbars */
.description3::-webkit-scrollbar {
  width: 10px;
  background-color: #f1f1f1;
}

.description3::-webkit-scrollbar-thumb {
  background-color: #888;
}

.textareaInput::-webkit-scrollbar {
  width: 10px;
  background-color: #f1f1f1;
}

.textareaInput::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* END inner textarea classes */

/*------------------------- Outer image containers (not imagepreview) ------------------------------*/

/* Image class for main image of panel 2 */
.right {
  margin-top: calc(1.2vw * 3 / 4);
  height: calc(20vw * 3 / 4);
  float: right;
  position: static;
  overflow: hidden;
  width: 19vw;
  margin-right: 2.2vw;
  border-radius: 10px;
  text-align: left;
}

/* Outer parent container for all images in panel 3 */
.rotatedImagesContainer {
  margin-top: calc(0.75vw * 3 / 4);
  height: calc(22vw * 3 / 4);
  width: 45vw;
}

/* wrapper for left image of panel 3 */
.tiltedWrapperLeft {
  float: left;
  position: relative;
  width: 22.5vw;
  top: calc(3.5vw * 3 / 4);
}

/* innermost parent class for left image of panel 3 */
.tiltedLeftp3 {
  margin-left: 4.5vw;
  max-width: 14vw;
  max-height: calc(14vw * 3 / 4);
  height: calc(14vw * 3 / 4);
  min-height: calc(14vw * 3 / 4);
  transform: rotate(-25deg);
}

/* wrapper for right image of panel 3 */
.tiltedWrapperRight {
  float: right;
  position: relative;
  width: 22.5vw;
  top: calc(3.5vw * 3 / 4);
}

/* innermost parent class for right image of panel 3 */
.tiltedRightp3 {
  transform: rotate(25deg);
  margin-left: 2.5vw;
  max-width: 14vw;
  min-width: 14vw;
  max-height: calc(14vw * 3 / 4);
  height: calc(14vw * 3 / 4);
  min-height: calc(14vw * 3 / 4);
}

/* Outer parent container for all images in panel 4 */
.imageContainer4 {
  margin-top: calc(3vw * 3 / 4);
  width: 45vw;
}

/* parent class for left image in panel 4 */
.tiltedLeftp4 {
  transform: rotate(-25deg);
  width: 12vw;
  height: calc(13vw * 3 / 4);
  min-height: calc(13vw * 3 / 4);
  margin-left: 2vw;
}

/* parent class for middle image in panel 4 */
.middle4 {
  width: 12vw;
  height: calc(13vw * 3 / 4);
  margin-left: 16.25vw;
  margin-top: calc(-17vw * 3 / 4);
}

/* parent class for right image in panel 4 */
.tiltedRightp4 {
  transform: rotate(25deg);
  width: 12vw;
  height: calc(13vw * 3 / 4);
  margin-left: 30.5vw;
  margin-top: calc(-9vw * 3 / 4);
}

/* Image class for main image of panel 5 */
.container5 {
  margin-top: calc(-35.3vw * 3 / 4);

  /* calculate height and aspect ratio*/
  height: calc(38vw * 3 / 4);

  /* width of 55vw */
  width: 44.59vw;

  /* rounded corners */
  border-radius: 10px;
  /* offset from bottom */
  margin-bottom: calc(5vw * 3 / 4);

  position: relative;
  justify-content: center;
  padding: 0;
}

/* END image parent classes */

/*------------------------- imagepreviews (inner class/content of media divs) ------------------------------*/

/*
Not currently used but important for future reference
.imagePreview {
    position: relative;
    display: block;
    width: 19vw;
    height: 35vh;
    max-width: 19vw;
    max-height: 20vh;
    border-radius: 10px;
    border: solid;
    z-index: 0;
}
*/

/* image preview for panel 2 image */
.imagePreview2 {
  max-width: 19vw;
  min-width: 19vw;
  height: calc(20vw * 3 / 4);
  object-fit: fill;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid;
  z-index: 0;
}

/* image preview for panel 3 images */
.imagePreview3 {
  max-width: 14vw;
  min-width: 14vw;
  max-height: calc(14vw * 3 / 4);
  height: inherit;
  min-height: calc(14vw * 3 / 4);
  object-fit: fill;
  background-repeat: no-repeat;
  box-sizing: border box;
  border-radius: 10px;
  border: solid;
}

/* image preview for panel 4 images */
.imagePreview4 {
  display: block;
  width: 12vw;
  height: 20vh;
  max-width: 12vw;
  height: calc(13vw * 3 / 4);
  min-height: calc(13vw * 3 / 4);
  border-radius: 10px;
  border: solid;
}

/* image preview for panel 5 image */
.imagePreview5 {
  width: 44.59vw;
  height: inherit;
  border-radius: 10px;
  border: solid;
  z-index: 1;
}

/* END imagepreviews */
/* misc comment for final push */
/*!!!!!!!!!!!!!!!!!! END Panel styles !!!!!!!!!!!!!!!!!!!*/

/* AI generated overlay css just for testing of the recovery code overlay */
/* TODO: replace this with proper styling, fit it into the stylesheet wit hall the other componenets */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.tagBox {
  padding: 10px;
  border-radius: 5px;
  background-color: #f3f3f3; /* Or any preferred background */
}

.tagForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.Tag {
  padding: 8px 12px;
  border-radius: 25px;
  background-color: #e0e0e0;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  transition: background-color 0.3s;
}

.TagSelected {
  background-color: #009688; /* Or any preferred highlight color */
  border-color: #00796b;
}
